/* Fonts */

// $serif-font-stack: "Source Serif Pro", "Times New Roman",serif;
$sans-font-stack: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
// $helvetica: "Helvetica Neue", Helvetica, Arial, sans-serif;
$code-font-stack: "Iosevka", monospace;

@font-face {
    font-family: 'Source Sans Pro';
    font-weight: 200;
    font-style: normal;
    font-stretch: normal;
    src: url('/fonts/EOT/SourceSansPro-ExtraLight.eot') format('embedded-opentype'), url('/fonts/WOFF/OTF/SourceSansPro-ExtraLight.otf.woff') format('woff'), url('/fonts/OTF/SourceSansPro-ExtraLight.otf') format('opentype'), url('/fonts/TTF/SourceSansPro-ExtraLight.ttf') format('truetype');
}

@font-face {
    font-family: 'Source Sans Pro';
    font-weight: 200;
    font-style: italic;
    font-stretch: normal;
    src: url('/fonts/EOT/SourceSansPro-ExtraLightIt.eot') format('embedded-opentype'), url('/fonts/WOFF/OTF/SourceSansPro-ExtraLightIt.otf.woff') format('woff'), url('/fonts/OTF/SourceSansPro-ExtraLightIt.otf') format('opentype'), url('/fonts/TTF/SourceSansPro-ExtraLightIt.ttf') format('truetype');
}

@font-face {
    font-family: 'Source Sans Pro';
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    src: url('/fonts/EOT/SourceSansPro-Light.eot') format('embedded-opentype'), url('/fonts/WOFF/OTF/SourceSansPro-Light.otf.woff') format('woff'), url('/fonts/OTF/SourceSansPro-Light.otf') format('opentype'), url('/fonts/TTF/SourceSansPro-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Source Sans Pro';
    font-weight: 300;
    font-style: italic;
    font-stretch: normal;
    src: url('/fonts/EOT/SourceSansPro-LightIt.eot') format('embedded-opentype'), url('/fonts/WOFF/OTF/SourceSansPro-LightIt.otf.woff') format('woff'), url('/fonts/OTF/SourceSansPro-LightIt.otf') format('opentype'), url('/fonts/TTF/SourceSansPro-LightIt.ttf') format('truetype');
}

@font-face {
    font-family: 'Source Sans Pro';
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    src: url('/fonts/EOT/SourceSansPro-Regular.eot') format('embedded-opentype'), url('/fonts/WOFF/OTF/SourceSansPro-Regular.otf.woff') format('woff'), url('/fonts/OTF/SourceSansPro-Regular.otf') format('opentype'), url('/fonts/TTF/SourceSansPro-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Source Sans Pro';
    font-weight: 400;
    font-style: italic;
    font-stretch: normal;
    src: url('/fonts/EOT/SourceSansPro-It.eot') format('embedded-opentype'), url('/fonts/WOFF/OTF/SourceSansPro-It.otf.woff') format('woff'), url('/fonts/OTF/SourceSansPro-It.otf') format('opentype'), url('/fonts/TTF/SourceSansPro-It.ttf') format('truetype');
}

@font-face {
    font-family: 'Source Sans Pro';
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    src: url('/fonts/EOT/SourceSansPro-Semibold.eot') format('embedded-opentype'), url('/fonts/WOFF/OTF/SourceSansPro-Semibold.otf.woff') format('woff'), url('/fonts/OTF/SourceSansPro-Semibold.otf') format('opentype'), url('/fonts/TTF/SourceSansPro-Semibold.ttf') format('truetype');
}

@font-face {
    font-family: 'Source Sans Pro';
    font-weight: 600;
    font-style: italic;
    font-stretch: normal;
    src: url('/fonts/EOT/SourceSansPro-SemiboldIt.eot') format('embedded-opentype'), url('/fonts/WOFF/OTF/SourceSansPro-SemiboldIt.otf.woff') format('woff'), url('/fonts/OTF/SourceSansPro-SemiboldIt.otf') format('opentype'), url('/fonts/TTF/SourceSansPro-SemiboldIt.ttf') format('truetype');
}

@font-face {
    font-family: 'Source Sans Pro';
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    src: url('/fonts/EOT/SourceSansPro-Bold.eot') format('embedded-opentype'), url('/fonts/WOFF/OTF/SourceSansPro-Bold.otf.woff') format('woff'), url('/fonts/OTF/SourceSansPro-Bold.otf') format('opentype'), url('/fonts/TTF/SourceSansPro-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Source Sans Pro';
    font-weight: 700;
    font-style: italic;
    font-stretch: normal;
    src: url('/fonts/EOT/SourceSansPro-BoldIt.eot') format('embedded-opentype'), url('/fonts/WOFF/OTF/SourceSansPro-BoldIt.otf.woff') format('woff'), url('/fonts/OTF/SourceSansPro-BoldIt.otf') format('opentype'), url('/fonts/TTF/SourceSansPro-BoldIt.ttf') format('truetype');
}

@font-face {
    font-family: 'Source Sans Pro';
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    src: url('/fonts/EOT/SourceSansPro-Black.eot') format('embedded-opentype'), url('/fonts/WOFF/OTF/SourceSansPro-Black.otf.woff') format('woff'), url('/fonts/OTF/SourceSansPro-Black.otf') format('opentype'), url('/fonts/TTF/SourceSansPro-Black.ttf') format('truetype');
}

@font-face {
    font-family: 'Source Sans Pro';
    font-weight: 900;
    font-style: italic;
    font-stretch: normal;
    src: url('/fonts/EOT/SourceSansPro-BlackIt.eot') format('embedded-opentype'), url('/fonts/WOFF/OTF/SourceSansPro-BlackIt.otf.woff') format('woff'), url('/fonts/OTF/SourceSansPro-BlackIt.otf') format('opentype'), url('/fonts/TTF/SourceSansPro-BlackIt.ttf') format('truetype');
}

@font-face {
    font-family: 'Source Serif Pro';
    font-style: normal;
    font-weight: 400;
    src: local('Source Serif Pro'), url("SourceSerifPro-Regular.woff") format('woff');
}

@font-face {
    font-family: 'Source Serif Pro';
    font-style: italic;
    font-weight: 400;
    src: url("fonts/Heuristica-Italic.woff") format('woff');
}

@font-face {
    font-family: 'Source Serif Pro';
    font-style: normal;
    font-weight: 700;
    src: local('Source Serif Pro Bold'), url("/fonts/SourceSerifPro-Bold.woff") format('woff');
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: local('Source Sans Pro Bold'), url("/fonts/SourceSansPro-Regular.ttf.woff") format('woff');
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    src: local('Source Sans Pro Bold'), url("/fonts/SourceSansPro-Bold.ttf.woff") format('woff');
}

@font-face {
    font-family: 'Source Code Pro';
    font-style: normal;
    font-weight: 400;
    src: local('Source Code Pro'), url("/fonts/SourceCodePro-Regular.woff") format('woff');
}

@font-face {
    font-family: 'Iosevka';
    font-weight: 100;
    src: url('/fonts/iosevka/iosevka-ss08-thin.woff2') format('woff2'), url('/fonts/iosevka/iosevka-ss08-thin.woff') format('woff'), url('/fonts/iosevka/iosevka-ss08-thin.ttf') format('truetype')
}

@font-face {
    font-family: 'Iosevka';
    font-weight: 100;
    font-style: italic;
    src: url('/fonts/iosevka/iosevka-ss08-thinitalic.woff2') format('woff2'), url('/fonts/iosevka/iosevka-ss08-thinitalic.woff') format('woff'), url('/fonts/iosevka/iosevka-ss08-thinitalic.ttf') format('truetype')
}

@font-face {
    font-family: 'Iosevka';
    font-weight: 100;
    font-style: oblique;
    src: url('/fonts/iosevka/iosevka-ss08-thinoblique.woff2') format('woff2'), url('/fonts/iosevka/iosevka-ss08-thinoblique.woff') format('woff'), url('/fonts/iosevka/iosevka-ss08-thinoblique.ttf') format('truetype')
}

@font-face {
    font-family: 'Iosevka';
    font-weight: 200;
    src: url('/fonts/iosevka/iosevka-ss08-extralight.woff2') format('woff2'), url('/fonts/iosevka/iosevka-ss08-extralight.woff') format('woff'), url('/fonts/iosevka/iosevka-ss08-extralight.ttf') format('truetype')
}

@font-face {
    font-family: 'Iosevka';
    font-weight: 200;
    font-style: italic;
    src: url('/fonts/iosevka/iosevka-ss08-extralightitalic.woff2') format('woff2'), url('/fonts/iosevka/iosevka-ss08-extralightitalic.woff') format('woff'), url('/fonts/iosevka/iosevka-ss08-extralightitalic.ttf') format('truetype')
}

@font-face {
    font-family: 'Iosevka';
    font-weight: 200;
    font-style: oblique;
    src: url('/fonts/iosevka/iosevka-ss08-extralightoblique.woff2') format('woff2'), url('/fonts/iosevka/iosevka-ss08-extralightoblique.woff') format('woff'), url('/fonts/iosevka/iosevka-ss08-extralightoblique.ttf') format('truetype')
}

@font-face {
    font-family: 'Iosevka';
    font-weight: 300;
    src: url('/fonts/iosevka/iosevka-ss08-light.woff2') format('woff2'), url('/fonts/iosevka/iosevka-ss08-light.woff') format('woff'), url('/fonts/iosevka/iosevka-ss08-light.ttf') format('truetype')
}

@font-face {
    font-family: 'Iosevka';
    font-weight: 300;
    font-style: italic;
    src: url('/fonts/iosevka/iosevka-ss08-lightitalic.woff2') format('woff2'), url('/fonts/iosevka/iosevka-ss08-lightitalic.woff') format('woff'), url('/fonts/iosevka/iosevka-ss08-lightitalic.ttf') format('truetype')
}

@font-face {
    font-family: 'Iosevka';
    font-weight: 300;
    font-style: oblique;
    src: url('/fonts/iosevka/iosevka-ss08-lightoblique.woff2') format('woff2'), url('/fonts/iosevka/iosevka-ss08-lightoblique.woff') format('woff'), url('/fonts/iosevka/iosevka-ss08-lightoblique.ttf') format('truetype')
}

@font-face {
    font-family: 'Iosevka';
    src: url('/fonts/iosevka/iosevka-ss08-regular.woff2') format('woff2'), url('/fonts/iosevka/iosevka-ss08-regular.woff') format('woff'), url('/fonts/iosevka/iosevka-ss08-regular.ttf') format('truetype')
}

@font-face {
    font-family: 'Iosevka';
    font-style: italic;
    src: url('/fonts/iosevka/iosevka-ss08-italic.woff2') format('woff2'), url('/fonts/iosevka/iosevka-ss08-italic.woff') format('woff'), url('/fonts/iosevka/iosevka-ss08-italic.ttf') format('truetype')
}

@font-face {
    font-family: 'Iosevka';
    font-style: oblique;
    src: url('/fonts/iosevka/iosevka-ss08-oblique.woff2') format('woff2'), url('/fonts/iosevka/iosevka-ss08-oblique.woff') format('woff'), url('/fonts/iosevka/iosevka-ss08-oblique.ttf') format('truetype')
}

@font-face {
    font-family: 'Iosevka';
    font-weight: 500;
    src: url('/fonts/iosevka/iosevka-ss08-medium.woff2') format('woff2'), url('/fonts/iosevka/iosevka-ss08-medium.woff') format('woff'), url('/fonts/iosevka/iosevka-ss08-medium.ttf') format('truetype')
}

@font-face {
    font-family: 'Iosevka';
    font-style: italic;
    font-weight: 500;
    src: url('/fonts/iosevka/iosevka-ss08-mediumitalic.woff2') format('woff2'), url('/fonts/iosevka/iosevka-ss08-mediumitalic.woff') format('woff'), url('/fonts/iosevka/iosevka-ss08-mediumitalic.ttf') format('truetype')
}

@font-face {
    font-family: 'Iosevka';
    font-style: oblique;
    font-weight: 500;
    src: url('/fonts/iosevka/iosevka-ss08-mediumoblique.woff2') format('woff2'), url('/fonts/iosevka/iosevka-ss08-mediumoblique.woff') format('woff'), url('/fonts/iosevka/iosevka-ss08-mediumoblique.ttf') format('truetype')
}

@font-face {
    font-family: 'Iosevka';
    font-weight: 700;
    src: url('/fonts/iosevka/iosevka-ss08-bold.woff2') format('woff2'), url('/fonts/iosevka/iosevka-ss08-bold.woff') format('woff'), url('/fonts/iosevka/iosevka-ss08-bold.ttf') format('truetype')
}

@font-face {
    font-family: 'Iosevka';
    font-style: italic;
    font-weight: 700;
    src: url('/fonts/iosevka/iosevka-ss08-bolditalic.woff2') format('woff2'), url('/fonts/iosevka/iosevka-ss08-bolditalic.woff') format('woff'), url('/fonts/iosevka/iosevka-ss08-bolditalic.ttf') format('truetype')
}

@font-face {
    font-family: 'Iosevka';
    font-style: oblique;
    font-weight: 700;
    src: url('/fonts/iosevka/iosevka-ss08-boldoblique.woff2') format('woff2'), url('/fonts/iosevka/iosevka-ss08-boldoblique.woff') format('woff'), url('/fonts/iosevka/iosevka-ss08-boldoblique.ttf') format('truetype')
}

@font-face {
    font-family: 'Iosevka';
    font-weight: 900;
    src: url('/fonts/iosevka/iosevka-ss08-heavy.woff2') format('woff2'), url('/fonts/iosevka/iosevka-ss08-heavy.woff') format('woff'), url('/fonts/iosevka/iosevka-ss08-heavy.ttf') format('truetype')
}

@font-face {
    font-family: 'Iosevka';
    font-style: italic;
    font-weight: 900;
    src: url('/fonts/iosevka/iosevka-ss08-heavyitalic.woff2') format('woff2'), url('/fonts/iosevka/iosevka-ss08-heavyitalic.woff') format('woff'), url('/fonts/iosevka/iosevka-ss08-heavyitalic.ttf') format('truetype')
}

@font-face {
    font-family: 'Iosevka';
    font-style: oblique;
    font-weight: 900;
    src: url('/fonts/iosevka/iosevka-ss08-heavyoblique.woff2') format('woff2'), url('/fonts/iosevka/iosevka-ss08-heavyoblique.woff') format('woff'), url('/fonts/iosevka/iosevka-ss08-heavyoblique.ttf') format('truetype')
}
