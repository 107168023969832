
$text-body-grey: #515151;
$text-body-green: #76946A;
$dark-background: #16161D;
$text-light-grey: hsl(0, 0%, 40%);
$text-link-grey: #479;

$ultralight-grey: #aaa;
$text-header-black:  #333;

$autumnRed: #C34043;
$autumnYellow:	#DCA561;
$autumnGreen: #76946A;
$oniViolet: #957FB8;
$sumiInk4:	#54546D;
$fujiWhite: #DCD7BA;
