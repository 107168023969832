@import "fonts.scss";
@import "term.scss";
@import "colors.scss";
/* Common vars */
* {
  text-rendering: geometricPrecision,
}

body {
  background-color: $dark-background;
  font: 1rem $code-font-stack;
  color: $autumnYellow;
  -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
}

h1, h2, h3, h4, h5, h6 {
  font: 1rem $code-font-stack;
  color: $oniViolet;
  line-height: 1;
  margin-top: 1rem;
  margin-bottom: 1rem;
  &:before {
    color: $ultralight-grey;
    border-bottom: none;
  }
}
h1 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
h2 {
  // font-size: 160%;
  margin-top: 1.8rem;
  margin-bottom: 1.8rem;
}
h3 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-weight: 400;
}

h1:before { content: "# "; }
h2:before { content: "## "; }
h3:before { content: "### "; }
h4:before { content: "#### "; }

h1, h2, h3 {
  font-weight: 600;
}

h1, h2 {
  text-transform: uppercase;
  & .title {
    letter-spacing: 0.1px;
    text-align: center;
    outline: none;
    border: none;
  }
  & .halftitle {
    display: inline-block;
    float: right;
    width: auto;
    height: auto;
    text-align: right;
  }

  & .blog-title {
      text-align: left;
      // font-size: 26px;
  }
}


div.picheader, div.textheader {
  width: 100%;
  height: auto;
  display: inline-block;
  margin-top: 1.5rem;
  margin-bottom: 0.2rem;
}

div.halftitle {
  height: 124px;
  width: 430px;
  float: right;
  margin: auto;
}

.content p {
  clear: both;
  // margin: 1em 0;
  padding: 0;
  margin-left: 2ch;
  text-align: left;
  line-height : 1.5em;
}

a {
    color: $autumnRed;
    text-decoration: none;
    &:hover {
        border-bottom: 2px solid;
    }
}
//a:visited { color: #696969; }

img.titleimage {
  float: left;
  border:2px solid black;
  border-radius:8px;
}


div.clear {
  clear: both;
}

div.content {
  clear: both;
}

p.sidenote {
  ::before {
    content: "::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::";
  }
}


/*
 * Messages
 *
 * Show alert messages to users. You may add it to single elements like a `<p>`,
 * or to a parent if there are multiple elements to show.
 */

$diagnostic: #6A9589;
$lighter-bg: #2A2A37;

.message {
  margin-bottom: 1rem;
  padding: 1rem;
  color: $diagnostic;
  background-color: $lighter-bg;
}

/*** site ***/

.site {
  // font-size: 115%;
  width: 80ch;
  margin: 2em auto;
}

div.top-menu, div.footer {
  height: auto;
  width: 100%;
  display: inline-block;
  clear: both;
}

div.top-menu {
  border-bottom: 3px solid #eee;
 }

.top-menu a {
  font-weight: 500;
  text-decoration: none;
}
/*.top-menu a:hover {
  background-color: white;
  border: 2px solid white;
}*/

div.above-post, div.below-post {
  display: inline-block;
  height: auto;
  width: 100%;
}

div.above-post {
  margin-bottom: .7em;
}

div.below-post {
  margin-top: .7em;
}

.above-post .back, .below-post .back {
  float:left;
}

.above-post .meta, .below-post .meta {
  color: $autumnYellow;
  padding: 2px;
  width: 75%;
}

.above-post .meta {
  padding-top: .4em;
  float: right;
  text-align: right;
}

.below-post .meta {
  color: $autumnYellow;
  padding-top: 2em;
  float: left;
  text-align: left;
}

.below-post .author {
  padding-above: -800px;
  font-size: 120%;
  width: auto;
  height: auto;
  float: right;
  text-align: right;
  color: $autumnYellow;
}

div.footer {
  font-size: 80%;
  font-family: $sans-font-stack;
  color: $autumnYellow;
  border-top: 3px solid #eee;
  margin: 2.5em auto 1em;
  padding-top: 1em;
  line-height: 1.5;
}

.footer .contact-left, .footer .contact-right {
  width: 49%;
}

.footer .contact-left {
  text-align: right;
  float: left;
  margin-right: 1%;
}

.footer .contact-right {
  text-align: left;
  float: right;
  margin-left: 1%;
}

a.extra {
  color: $autumnYellow;
  text-decoration: none;
}

.top-menu a.extra {
  margin-left: 1em;
}

.contact-left a.extra, .contact-right a.extra, .below-post a.extra {
  color: $autumnYellow;
}

a.extra:hover {
  color: #000;
}

// p.navigation {
//   clear: both;
//   text-align: center;
//   width: 100%;
//   padding-top: 2em;
//   font-size: 80%;
//   font-family: $sans-font-stack;
//   line-height: 1;
// }

/*** lists ***/

ul {
  list-style-type: "*";
  margin-left: 1.5rem;
  padding: 0;
}
//
// li {
//   font-family: $sans-font-stack;
//   font-size: 95%;
//   line-height: 1.5;
// }

// ul li:before {
//   display: inline-block;
//   content: "–";
//   width: 1em;
//   margin-left: -1em;
//   font-weight: bold;
// }

ul.posts {
  content: "-";
  margin-left: -1em;
  font-weight: bold;
}

.post ul {
  li:before {
    display: inline-block;
    content: "–";
    width: 1em;
    margin-left: -1em;
    font-weight: bold;
  }
}


/* Lists */
ul, ol, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

dt {
  font-weight: bold;
}
dd {
  margin-bottom: .5rem;
}

/* Misc */
hr {
  position: relative;
  margin: 1.5rem 0;
  border: 0 {
    top: 1px solid #eee;
    bottom: 1px solid #fff;
  }
}

abbr {
  font-size: 85%;
  font-weight: bold;
  color: $autumnYellow;
  text-transform: uppercase;
}
abbr[title] {
  cursor: help;
  border-bottom: 1px dotted #e5e5e5;
}

/* Code */
code, pre {
  font-family: $code-font-stack;
}
code {
  padding: .25em .5em;
  font-size: 85%;
  color: $autumnRed;
  background-color: $fujiWhite;
  background-clip: padding-box;
}

a code {
  color: $autumnYellow;
  // text-decoration: none;
  &:hover {
      border-bottom: 2px solid;
  }
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  padding: 1rem;
  font-size: .8rem;
  line-height: 1.4;
  white-space: pre;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f9f9f9;
  code {
    padding: 0;
    font-size: 100%;
    color: inherit;
    background-color: transparent;
  }
}


/* Pygments via Jekyll */
.highlight {
  margin-bottom: 1rem;
  border-radius: 4px;
}
.highlight pre {
  margin-bottom: 0;
}

/* Gist via GitHub Pages */
.gist .gist-file {
  font-family: $code-font-stack !important;
}
.gist .markdown-body {
  padding: 1rem;
}
.gist pre {
  padding: 0;
  background-color: transparent;
}
.gist .gist-file .gist-data {
  font-size: .8rem !important;
  line-height: 1.4;
}
.gist code {
  padding: 0;
  color: inherit;
  background-color: transparent;
  border-radius: 0;
}

/* Quotes */
blockquote {
  padding: .5rem 1rem;
  margin: .8rem 0;
  color: #7a7a7a;
  border-left: .25rem solid #e5e5e5;
}
blockquote p:last-child {
  margin-bottom: 0;
}
@media (min-width: 30em) {
  blockquote {
    padding-right: 5rem;
    padding-left: 1.25rem;
  }
}

img {
  display: block;
  max-width: 100%;
  margin: 0 0 1rem;
  border-radius: 5px;
}

/* Tables */
// table {
  //   margin-bottom: 1rem;
  //   width: 100%;
  //   border: 1px solid #e5e5e5;
  //   border-collapse: collapse;
  // }
  // td,
  // th {
  //   padding: .25rem .5rem;
  //   border: 1px solid #e5e5e5;
  // }
  // tbody tr:nth-child(odd) td,
  // tbody tr:nth-child(odd) th {
  //   background-color: #f9f9f9;
  // }
/*
 * Posts and pages
 *
 * Each post is wrapped in `.post` and is used on default and post layouts. Each
 * page is wrapped in `.page` and is only used on the page layout.
 *
 *  Based on Poole (https://github.com/poole/poole/blob/master/public/css/poole.css)
 */

.page,
.post {
  margin-bottom: 4em;
}

/* Blog post or page title */
.page-title,
.post-title,
.post-title a {
  color: $autumnRed;
}
.page-title,
.post-title,
.subtitle {
  margin-top: 0;
}

.post-title,
.subtitle {
  &:before {
    content: none;
  }
  margin-bottom: 0;
}

.post-title {
  font-size: 140%;
}

.subtitle {
  color: $ultralight-grey;
  font-weight: 400;
}

/* Meta data line below post title */
.post-date {
  display: block;
  paddin-top: 0;
  margin-top: 0;
  margin-bottom: 1rem;
  float: left;
  color: $ultralight-grey;
}

/* Related posts */
.related {
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-top: 1px solid #eee;
}
.related-posts {
  padding-left: 0;
  list-style: none;
}
.related-posts h3 {
  margin-top: 0;
}
.related-posts li small {
  font-size: 75%;
  color: #999;
}
.related-posts li a:hover {
  color: #268bd2;
  text-decoration: none;
}
.related-posts li a:hover small {
  color: inherit;
}


/*
 * Pagination
 *
 * Super lightweight (HTML-wise) blog pagination. `span`s are provide for when
 * there are no more previous or next posts to show.
 *
 * Based on Poole (https://github.com/poole/poole/blob/master/public/css/poole.css)
 */

.pagination {
  overflow: hidden; /* clearfix */
  margin-left: -1rem;
  margin-right: -1rem;
  font-family: $sans-font-stack;
  color: #ccc;
  text-align: center;
}

/* Pagination items can be `span`s or `a`s */
.pagination-item {
  display: block;
  padding: 1rem;
  border: 1px solid #eee;
}
.pagination-item:first-child {
  margin-bottom: -1px;
}

/* Only provide a hover state for linked pagination items */
a.pagination-item:hover {
  background-color: #f5f5f5;
}

@media (min-width: 30em) {
  .pagination {
    margin: 3rem 0;
  }
  .pagination-item {
    float: left;
    width: 50%;
  }
  .pagination-item:first-child {
    margin-bottom: 0;
    border-top-left-radius:    4px;
    border-bottom-left-radius: 4px;
  }
  .pagination-item:last-child {
    margin-left: -1px;
    border-top-right-radius:    4px;
    border-bottom-right-radius: 4px;
  }
}
